import { Queue } from 'async-fifo-queue';
import { detect } from 'detect-browser';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function fastHash(str, seed = 0) {
  // https://stackoverflow.com/a/52171480/8194139
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function buildQuery(object) {
  return Object.entries(object)
    .filter(([_, v]) => v != null)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');
}

// There is a special hook "useSearchParams" from "react-router-dom" that does exactly the same thing except
// it returns a URLSearchParams object (+ a set function) and not a raw object, which is what I wanted
export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
}

export function useObjectMemo(object) {
  const cache = useRef(object);
  if (JSON.stringify(cache.current) !== JSON.stringify(object)) {
    cache.current = object;
  }
  return cache.current;
}

export async function httpFetch(url, options, success, error, done) {
  try {
    const resp = await fetch(url, { ...options, credentials: 'same-origin' });
    if (resp.ok) {
      if (resp.headers.get('content-type')?.startsWith('application/json')) {
        success(await resp.json());
      } else {
        success(await resp.text());
      }
    } else {
      error(`${resp.status} ${resp.statusText}`);
    }
  } catch (err) {
    console.error(err);
    error(err.toString());
  } finally {
    if (done) {
      done();
    }
  }
}

export const httpGet = (url, ...args) => httpFetch(url, { method: 'GET' }, ...args);

export function bounded(f, n) {
  const q = new Queue(n);
  return async (...args) => {
    await q.put(null);
    const res = await f(...args);
    q.getNowait();
    return res;
  };
}

export const boundedGet = bounded(httpGet, 30);

export function range(start, stop, step = 1) {
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
}

export function notFullScreen() {
  return (
    !document.fullscreenElement && // alternative standard method
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  );
}

export const VIDEO_ELEMENT_ID = 'videoElement';

export function ToggleFullScreen(id) {
  const videoElement = document.getElementById(id ?? VIDEO_ELEMENT_ID);
  const notFS = notFullScreen();

  if (notFS) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      videoElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      videoElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  }
}

export function getFormatFromFamily(family) {
  if (family.indexOf('dsh') !== -1 || family.indexOf('dash') !== -1) {
    return 'dash';
  } else if (family.indexOf('hss') !== -1) {
    return 'smooth';
  } else if (family.indexOf('hls') !== -1) {
    return 'hls';
  }
}

export function getFormatFromUrl(url) {
  if (url.endsWith('.mpd')) {
    return 'dash';
  }
  if (url.endsWith('.m3u8')) {
    return 'hls';
  }
  return 'smooth';
}

export function getServiceIdFromMediaKey(DRMServiceId, mediaKey) {
  if (mediaKey.indexOf('CPLUS') !== -1) {
    return DRMServiceId['Canal+ à la demande'];
  } else if (mediaKey.indexOf('M6REPLAY') !== -1) {
    return DRMServiceId['6Play'];
  } else if (mediaKey.indexOf('mvs_movie') !== -1) {
    return DRMServiceId['Canalplay VOD'];
  } else if (mediaKey.indexOf('CHDE') !== -1) {
    return DRMServiceId['Catchup StreamGroupM7'];
  } else {
    return DRMServiceId['CanalSat à la demande'];
  }
}

export const dtf = new Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric', second: 'numeric' });

export function S2TimeString(s) {
  return new Date(s * 1000).toISOString().slice(11, 19);
}

export function getCdnName(urlString) {
  const { hostname, pathname } = new URL(urlString);

  if (pathname.startsWith('/plm/')) {
    return 'PlaylistMaker V1';
  }
  if (pathname.startsWith('/composer/channels/')) {
    return 'PlaylistMaker V2';
  }
  if (hostname.match(/live-scy|scy.canal(plus|bis)-cdn.net|scy-telia/)) {
    return 'scOTTy';
  }
  if (hostname.match(/akamaized.net|live-aka|akamaihd.net|aka-storage/)) {
    return 'Akamai';
  }
  if (hostname.match(/(live|vod|snl)-lv3/)) {
    return 'Lumen';
  }
  if (hostname.match(/debug.canalplus-cdn.net/)) {
    return 'TDR';
  }
  if (hostname.match(/ncplus.pl|canalplus.pl/)) {
    return 'Canal Plus Poland';
  }
  if (hostname.match(/orange.pl/)) {
    return 'Orange Poland';
  }
  if (hostname.match(/live-cft.canal(plus|bis)-cdn.net/)) {
    return 'CloudFront';
  }
  if (hostname.indexOf('solocoo') !== -1) {
    return 'Broadpeak';
  }
  return 'Unknown source';
}

export const analysisRootUrl = {
  smooth: '/manifest',
  dash: '/mpd',
  hls: '/m3u8/master',
  hlsMedia: '/m3u8/media',
};

export const drmSystemInfo = {
  playreadyHw: { domain: 'com.microsoft.playready.hardware', name: 'Playready' },
  playready: { domain: 'com.microsoft.playready', name: 'Playready' },
  widevine: { domain: 'com.widevine.alpha', name: 'Widevine' },
};

export const drmNames = [...new Set(Object.values(drmSystemInfo).map((e) => e.name)), 'FairPlay'];

export const drmIds = {
  'urn:uuid:9a04f079-9840-4286-ab92-e65be0885f95': 'Microsoft PlayReady',
  'urn:mpeg:dash:mp4protection:2011': 'MPEG DASH',
  'urn:uuid:adb41c24-2dbf-4a6d-958b-4457c0d27b95': 'Nagra MediaAccess PRM 3.0 and above',
  'urn:uuid:94CE86FB-07FF-4F43-ADB8-93D2FA968CA2': 'Fairplay Streaming',
  'urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed': 'Widevine',
  'urn:uuid:1077efec-c0b2-4d02-ace3-3c1e52e2fb4b': 'W3C Common PSSH box',
};

export function url2Format(url) {
  const st = url.split('?')[0];
  if (st.match(/m3u8$/i)) {
    return 'hls';
  }
  if (st.match(/(ism|isml|manifest)$/i)) {
    return 'smooth';
  }
  if (st.match(/mpd$/i)) {
    return 'dash';
  }
}
// Convert Uint array to string
export function arrayToString(array) {
  const uint16array = new Uint16Array(array.buffer);
  return String.fromCharCode.apply(null, uint16array);
}

export const browser = detect();

export function showPlayButton(url) {
  const format = url2Format(url.split('?')[0]);
  if (!format) {
    return false;
  }
  if (browser && ['safari', 'ios'].includes(browser.name) && browser.os.toLowerCase().indexOf('windows') === -1) {
    return format === 'hls';
  } else {
    return url.startsWith('https') && ['dash', 'smooth'].includes(format);
  }
}

export const allColumns = (objects) => [...new Set(objects.flatMap(Object.keys))];

const periodStartReg = /^PT((?<hours>[\d.]*)H)?((?<minutes>[\d.]*)M)?((?<seconds>[\d.]*)S)?$/;

function periodStartToSeconds(str) {
  const { hours, minutes, seconds } = periodStartReg.exec(str).groups;
  return Number(hours ?? 0) * 3600 + Number(minutes ?? 0) * 60 + Number(seconds ?? 0);
}

class HTTPError extends Error {
  constructor(message) {
    super(message);
    this.canRetry = true;
  }
}

let i = 0;
const domParser = new DOMParser();

export async function manifestLoader({ url }, callback, setState, currentPeriods) {
  try {
    const resp = await fetch(url);
    if (resp.ok) {
      const data = await resp.text();
      const doc = domParser.parseFromString(data, 'application/xml');
      const periods = [];
      for (const p of doc.querySelectorAll('Period')) {
        periods.push({
          id: p.getAttribute('id') ?? `gen-dash-period-${i++}`,
          start: periodStartToSeconds(p.getAttribute('start') ?? 'PT0S'),
          schemeIdUri: p.querySelector('EventStream')?.getAttribute('schemeIdUri') ?? '',
        });
      }
      const reversed = periods.reverse();
      if (JSON.stringify(reversed) !== JSON.stringify(currentPeriods)) {
        setState({ periods: reversed }, () => callback.resolve({ data, url: resp.url }));
      } else {
        callback.resolve({ data, url: resp.url });
      }
    } else {
      callback.reject(new HTTPError(`Couldn't fetch MPD: HTTP ${resp.status}`));
    }
  } catch (err) {
    callback.reject(err);
  }
}
