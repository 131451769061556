import React, { useContext, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Alert, Badge, ButtonGroup, Col, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped, TooltipedButton } from '../Components/CommonButtons';
import { KVCard } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import Paginate from '../Components/Paginate';
import ctx from '../context';
import { boundedGet, buildQuery, getFormatFromFamily, getServiceIdFromMediaKey, httpGet, showPlayButton, useQueryParams } from '../utils';

const RoutesContext = React.createContext();
const ITEMS_PER_PAGE = 10;
// I didn't want to pass down refs everywhere so yeah, global scoped variables
// Don't like it ? Don't use it
let httpCallbacks = {};
let httpResults = {};
let visibleRoutes = {};

const playlistType = {
  dash: 'mpd',
  smooth: 'manifest',
  hls: 'm3u8/master',
};

function matchInput(str, filter) {
  if (filter === '') {
    return true;
  }
  str = str.toLowerCase();
  filter = filter.toLowerCase();
  if (filter.startsWith('!')) {
    return str.indexOf(filter.slice(1)) === -1;
  }
  return str.indexOf(filter) !== -1;
}

function matchStatus(k, statusFilter) {
  if (statusFilter === 'all') {
    return true;
  }
  const httpStatus = httpResults[k]?.at(-1);
  if (httpStatus == null) {
    return false;
  }
  return statusFilter === 'ok' ? httpStatus === 200 : httpStatus >= 400;
}

function statusToIcon(status, loading) {
  if (loading) {
    return <Spinner variant='primary' size='sm' />;
  }
  if (status == null) {
    return null;
  }
  if (typeof status === 'string') {
    return (
      <Tooltiped text={status}>
        <Badge bg='warning' text='dark'>
          unknown
        </Badge>
      </Tooltiped>
    );
  }
  if (status === 200) {
    return <Badge bg='success'>{status}</Badge>;
  }
  return <Badge bg='danger'>{status}</Badge>;
}

function Stream(props) {
  const { subdir, zone, family, format, analysisSuffix, vod, disableButton } = useContext(RoutesContext);
  const lastStatus = httpResults[`${props.desc}_${props.group}_${props.name}`];
  // status is last items in array
  const [status, setStatus] = useState(lastStatus ? lastStatus.at(-1) : null);
  const [loading, setLoading] = useState(false);

  const drmId = vod?.drmId;
  const version = vod?.version;
  const bif = vod?.bif;

  const queryStringButtons = buildQuery({
    media: props.url,
    format,
    asset: props.desc + (props.epgid ? ` (${props.epgid})` : ''),
    t0: props.t0,
    DRMServiceId: props.drmserviceid,
    licenseVersion: props.licenseversion,
    drmId,
    version,
    bif,
  });

  useEffect(() => {
    visibleRoutes[`${props.desc}_${props.group}_${props.name}`] = [props.desc, props.group, props.name, props.url];
    return () => delete visibleRoutes[`${props.desc}_${props.group}_${props.name}`];
  }, [props.url, props.desc, props.group, props.name]);

  useEffect(() => {
    httpResults[`${props.desc}_${props.group}_${props.name}`] = [props.desc, props.group, props.name, props.url, status];
  }, [status, props.desc, props.group, props.name, props.url]);

  useEffect(() => {
    httpCallbacks[props.url] = function (setOks, setKos, setUnknowns, setLoadings) {
      setLoading(true);
      setLoadings((n) => n + 1);
      boundedGet(
        `/api/proxy?url=${encodeURIComponent(props.url)}`,
        (body) => {
          if (body.code >= 400) {
            setKos((n) => n + 1);
          } else {
            setOks((n) => n + 1);
          }
          setStatus(body.code);
        },
        (err) => {
          setUnknowns((n) => n + 1);
          setStatus(err);
        },
        () => {
          setLoading(false);
          setLoadings((n) => n - 1);
        }
      );
    };
    return () => delete httpCallbacks[props.url];
  }, [props.url, subdir, zone, family, props.name, props.group, props.desc, vod]);

  return (
    <tr>
      <td style={{ whiteSpace: 'nowrap' }}>
        <ButtonGroup size='sm'>
          <Tooltiped text='play stream'>
            <LinkButton to={`/player?${queryStringButtons}`} size='sm' disabled={disableButton}>
              <i className='fas fa-play' />
            </LinkButton>
          </Tooltiped>
          <AnalysisButton as={Link} to={`/${analysisSuffix}?${queryStringButtons}`} />
          <CopyButton text={props.url} />
        </ButtonGroup>
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>{props.group}</td>
      <td style={{ whiteSpace: 'nowrap' }}>{props.name}</td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <samp>
          {statusToIcon(status, loading)} {props.url}
        </samp>
      </td>
    </tr>
  );
}

function Channel(props) {
  const { subdir, zone, family, vod, vodDrmServiceId, disableButton } = useContext(RoutesContext);
  const serviceId = vodDrmServiceId ?? props.drmserviceid;
  const queryStringMosaic = buildQuery({
    DRMServiceId: serviceId,
    licenseVersion: props.licenseversion,
    subdir,
    zone,
    family,
    asset: props.desc,
    mode: vod ? 'vod' : 'sites',
    contentPath: vod?.contentPath,
    drmId: vod?.drmId,
  });

  return (
    <>
      <tr className='table-primary'>
        <td></td>
        <td>
          {props.routemeup && (
            <TooltipedButton
              text='RouteMeUp'
              className='me-1'
              size='lg'
              style={{ padding: 0 }}
              variant='link'
              href={props.routemeup}
              target='_blank'>
              <i className='fas fa-signs-post' />
            </TooltipedButton>
          )}
          {props.contentid && (
            <Tooltiped text={`P2P [${props.contentid}]`}>
              <i className='fas fa-share-nodes text-primary h5' />
            </Tooltiped>
          )}
        </td>
        <td>
          <TooltipedButton
            text='Mosaic'
            size='lg'
            style={{ padding: 0 }}
            variant='link'
            disabled={disableButton}
            href={`/mosaique?${queryStringMosaic}`}
            target='_blank'>
            <i className='fas fa-table-cells' />
          </TooltipedButton>
        </td>
        <td colSpan={100}>
          <h5>
            <b>
              {props.desc} {props.epgid != null && `(${props.epgid})`}
            </b>
          </h5>
        </td>
      </tr>
      {props.streams
        .filter(
          (s) =>
            matchStatus(`${props.desc}_${s.group}_${s.name}`, props.statusFilter) &&
            matchInput(s.group, props.searchType) &&
            matchInput(s.name, props.searchRoute) &&
            matchInput(s.url, props.searchUrl)
        )
        .map((s) => (
          <Stream
            key={s.group + s.name}
            {...s}
            desc={props.desc}
            epgid={props.epgid}
            drmserviceid={serviceId}
            licenseversion={props.licenseversion}
          />
        ))}
    </>
  );
}

function Channels({ asset, type, route, url, ipp, channels, checkAllRef }) {
  const [page, setPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState('all');
  const { vod } = useContext(RoutesContext);

  // useMemo because the list doesn't change when ipp changes
  const filteredChannels = useMemo(() => {
    return channels
      .filter(
        (c) =>
          matchInput(c.desc + (c.epgid ?? ''), asset) &&
          c.streams.some(
            (s) =>
              matchInput(s.group, type) &&
              matchInput(s.name, route) &&
              matchInput(s.url, url) &&
              matchStatus(`${c.desc}_${s.group}_${s.name}`, statusFilter)
          )
      )
      .map((c) => <Channel key={c.desc} {...c} searchRoute={route} searchType={type} searchUrl={url} statusFilter={statusFilter} />);
  }, [asset, type, route, url, channels, statusFilter]);

  useEffect(() => {
    setPage(1);
  }, [ipp, filteredChannels.length]);

  const start = (page - 1) * ipp;
  const end = start + ipp;

  return (
    <>
      {vod && <KVCard title={vod.title} open obj={vod} />}
      <ChecksHTTP func={checkAllRef} setStatusFilter={setStatusFilter} />
      <div style={{ overflowX: 'scroll' }}>
        <Table hover size='sm'>
          <thead>
            <tr className='table-dark'>
              <th style={{ whiteSpace: 'nowrap' }}>{vod ? 'Action' : `${filteredChannels.length} channels`}</th>
              <th style={{ whiteSpace: 'nowrap' }}>URL type</th>
              <th style={{ whiteSpace: 'nowrap' }}>Route</th>
              <th style={{ whiteSpace: 'nowrap' }}>URL</th>
            </tr>
          </thead>
          <tbody>{filteredChannels.slice(start, end)}</tbody>
        </Table>
      </div>
      <Paginate page={page} total={filteredChannels.length} len={ipp} onChange={setPage} />
    </>
  );
}

function Routes({ channels }) {
  const { subdir, zone, family } = useContext(RoutesContext);
  const checkAllRef = useRef();
  const { asset, route, type, url } = useQueryParams();
  const defaultAsset = asset || '';
  const defaultRoute = route || '!TDR';
  const defaultType = type || '';
  const defaultUrl = url || '';
  const [inputs, setInputs] = useState({
    asset: defaultAsset,
    type: defaultType,
    route: defaultRoute,
    url: defaultUrl,
    ipp: ITEMS_PER_PAGE,
  });
  const [pending, startTransition] = useTransition();

  function onChangeInput(evt) {
    const { name, value } = evt.target;
    startTransition(() => setInputs((prev) => ({ ...prev, [name]: name === 'ipp' ? parseInt(value, 10) : value })));
  }

  function getList() {
    const csv = ['asset;type;route;url', ...Object.values(visibleRoutes).map((arr) => arr.join(';'))].join('\n');
    const file = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    const elt = document.createElement('a');
    elt.href = URL.createObjectURL(file);
    elt.download = `${subdir}_${zone}_${family}.csv`;
    elt.click();
    elt.remove();
  }

  return (
    <Stack gap='3'>
      <form>
        <Stack direction='horizontal' gap='2'>
          <InputComponent text='Asset'>
            <Form.Control defaultValue={defaultAsset} name='asset' onChange={onChangeInput} placeholder='search...' autoFocus />
          </InputComponent>
          <InputComponent text='URL type'>
            <Form.Control defaultValue={defaultType} name='type' onChange={onChangeInput} placeholder='search...' />
          </InputComponent>
          <InputComponent text='Route'>
            <Form.Control defaultValue={defaultRoute} name='route' onChange={onChangeInput} placeholder='search...' />
          </InputComponent>
          <InputComponent text='URL'>
            <Form.Control defaultValue={defaultUrl} name='url' onChange={onChangeInput} placeholder='search...' />
          </InputComponent>
          <TooltipedButton text='HTTP check URLs' onClick={() => checkAllRef.current()} disabled={pending}>
            <i className='fas fa-tasks' />
          </TooltipedButton>
          <DownloadButton text='Download URLs (only this page)' onClick={getList} disabled={pending} />
          <Col md='auto'>
            <Form.Select defaultValue={ITEMS_PER_PAGE} name='ipp' onChange={onChangeInput}>
              {[5, 10, 50, 100].map((n) => (
                <option key={n} value={n}>
                  {n} per page
                </option>
              ))}
              <option value={channels.length}>all on one page</option>
            </Form.Select>
          </Col>
        </Stack>
      </form>
      <Channels {...inputs} channels={channels} checkAllRef={checkAllRef} />
    </Stack>
  );
}

function ChecksHTTP({ func, setStatusFilter }) {
  const { subdir, zone, family } = useContext(RoutesContext);
  const [oks, setOks] = useState(0);
  const [kos, setKos] = useState(0);
  const [unknowns, setUnknowns] = useState(0);
  const [loadings, setLoadings] = useState(0);

  useEffect(() => {
    func.current = function () {
      setOks(0);
      setKos(0);
      setUnknowns(0);
      setLoadings(0);
      Object.values(httpCallbacks).map((fn) => fn(setOks, setKos, setUnknowns, setLoadings));
    };
  }, [func]);

  function downloadCsv() {
    // I loop on lastHttpChecks and not on httpResults directly because I want the http results of the last check, not of all
    // httpResults because I don't delete its entry on component unmount to remember status when filtering
    const csv = ['asset;type;route;url;status', ...Object.keys(visibleRoutes).map((k) => httpResults[k].join(';'))].join('\n');
    const file = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    const elt = document.createElement('a');
    elt.href = URL.createObjectURL(file);
    elt.download = `${subdir}_${zone}_${family}.csv`;
    elt.click();
    elt.remove();
  }

  if (oks + kos + unknowns + loadings === 0) {
    return null;
  }

  return (
    <Stack direction='horizontal' gap='2'>
      <span className='text-primary fw-bold'>Status:</span>
      <i className='fas fa-check-circle text-success' />
      {oks}
      <i className='fas fa-exclamation-circle text-danger' />
      {kos}
      <i className='fas fa-question-circle text-warning' />
      {unknowns}
      {loadings > 0 && (
        <>
          <Spinner variant='primary' size='sm' />
          {loadings}
        </>
      )}
      {loadings <= 0 && (
        <>
          <DownloadButton onClick={downloadCsv}>Get CSV report</DownloadButton>
          <Col md='2'>
            <InputComponent text='Filter by'>
              <Form.Select onChange={(evt) => setStatusFilter(evt.target.value)}>
                <option>all</option>
                <option>ok</option>
                <option>ko</option>
              </Form.Select>
            </InputComponent>
          </Col>
        </>
      )}
    </Stack>
  );
}

function Main() {
  httpCallbacks = {};
  httpResults = {};
  visibleRoutes = {};
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [routes, setRoutes] = useState(null);
  const { subdir, zone, family } = useParams();
  const [vod, setVod] = useState(null);
  const queryParams = useQueryParams();
  const format = getFormatFromFamily(family);
  const { DRMServiceId } = useContext(ctx);

  useEffect(() => {
    const query = { subdir, zone, family };
    if (queryParams.contentPath) {
      query.contentPath = queryParams.contentPath;
      query.desc = queryParams.title;
      setVod(queryParams);
    }
    httpGet(`/api/routes?${buildQuery(query)}`, setRoutes, setError, () => setLoading(false));
  }, [subdir, zone, family, queryParams]);

  return (
    <>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {routes && (
        <>
          <h2 className='mb-3'>
            {routes.zone} <small>{family}</small>
          </h2>
          <RoutesContext.Provider
            value={{
              subdir,
              zone,
              family,
              vod,
              format,
              analysisSuffix: playlistType[format],
              vodDrmServiceId: vod ? getServiceIdFromMediaKey(DRMServiceId, vod.mediaKey) : null,
              disableButton: !showPlayButton(routes.channels[0].streams[0].url),
            }}>
            <Routes channels={routes.channels} />
          </RoutesContext.Provider>
        </>
      )}
    </>
  );
}

export default Main;
